import { createContext, useReducer } from "react";
import { MAJOR_CATEGORY_ID } from "../constants/document";
import { BatterDocument } from "../types/document";
import { Action, ReactContext, ReactProps } from "../types/react";

type DocumentAction = Action & {
  document?: BatterDocument;
  newDocumentSortOrder?: number;
  newDocumentCategoryId?: number;
  newDocumentSubsectionId?: number;
};

type DocumentState = ReactContext<DocumentAction> & {
  addModalOpen: boolean;
  editModalOpen: boolean;
  deleteModalOpen: boolean;
  newDocumentSortOrder: number;
  newDocumentCategoryId: number;
  newDocumentSubsectionId: number;
  documentAdded: BatterDocument | null;
  documentEdited: BatterDocument | null;
  documentDeleted: BatterDocument | null;
  documentToEdit: BatterDocument | null;
  documentToDelete: BatterDocument | null;
};

const initialState: DocumentState = {
  dispatch: () => {},
  addModalOpen: false,
  editModalOpen: false,
  deleteModalOpen: false,
  documentAdded: null,
  documentEdited: null,
  documentDeleted: null,
  documentToEdit: null,
  documentToDelete: null,
  newDocumentSortOrder: 0,
  newDocumentCategoryId: MAJOR_CATEGORY_ID,
  newDocumentSubsectionId: 0
};

const DocumentContext = createContext(initialState);

const reducer = (state: DocumentState, action: DocumentAction): DocumentState => {
  switch (action.type) {
    case "showAddModal":
      return {
        ...state,
        addModalOpen: true,
        newDocumentSortOrder: action.newDocumentSortOrder || 0,
        newDocumentCategoryId: action.newDocumentCategoryId || MAJOR_CATEGORY_ID,
        newDocumentSubsectionId: action.newDocumentSubsectionId || 0
      };
    case "hideAddModal":
      return {
        ...state,
        addModalOpen: false
      };
    case "showEditModal":
      return {
        ...state,
        editModalOpen: true,
        documentToEdit: action.document || null
      };
    case "hideEditModal":
      return {
        ...state,
        editModalOpen: false
      };
    case "showDeleteModal":
      return {
        ...state,
        deleteModalOpen: true,
        documentToDelete: action.document || null
      };
    case "hideDeleteModal":
      return {
        ...state,
        deleteModalOpen: false
      };
    case "addedDocument":
      return {
        ...state,
        documentAdded: action.document || null
      };
    case "editedDocument":
      return {
        ...state,
        documentEdited: action.document || null
      };
    case "deletedDocument":
      return {
        ...state,
        documentDeleted: action.document || null
      };
    default:
      return state;
  }
};

const DocumentProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <DocumentContext.Provider value={{ ...state, dispatch }}>{children}</DocumentContext.Provider>;
};

export { DocumentContext, DocumentProvider };
