import { ValueOpt } from "best-common-react";
import { createContext, useReducer } from "react";
import { DistributionList } from "../types/distributionList";
import { IndividualMemoChange, Memo, MemoChange } from "../types/memo";
import { Action, ReactContext, ReactProps } from "../types/react";
import { listToDict } from "../utils/list";
import { removeMergedChangeFromChanges, replaceMemoChangesWithMergedChanges } from "../utils/memo";

type MemoAction = Action & {
  changes?: MemoChange[];
  changeToRemove?: IndividualMemoChange;
  individualChanges?: IndividualMemoChange[];
  distributionLists?: DistributionList[];
  selectedMemo?: Memo;
  submitted?: boolean;
};

type MemoState = ReactContext<MemoAction> & {
  _submitted: number;
  changes: MemoChange[];
  selectedChanges: MemoChange[];
  distributionListById: { [x: number]: DistributionList };
  distributionLists: DistributionList[];
  distributionListOptions: ValueOpt<number>[];
  selectedMemo: Memo | null;
};

const initialState: MemoState = {
  dispatch: () => {},
  _submitted: 0,
  changes: [],
  selectedChanges: [],
  distributionListById: {},
  distributionLists: [],
  distributionListOptions: [],
  selectedMemo: null
};

const MemoContext = createContext(initialState);

const reducer = (state: MemoState, action: MemoAction): MemoState => {
  // instance
  const {
    changes = [],
    individualChanges = [],
    distributionLists = [],
    selectedMemo = null,
    changeToRemove = null,
    submitted = false
  } = action;

  // logic
  switch (action.type) {
    case "submit": {
      return { ...state, _submitted: Math.random() };
    }
    case "clearSubmit": {
      return { ...state, _submitted: 0 };
    }
    case "setChanges": {
      return { ...state, changes };
    }
    case "setSelectedChanges": {
      return { ...state, selectedChanges: changes };
    }
    case "setDistributionLists": {
      return {
        ...state,
        distributionLists,
        distributionListById: listToDict(distributionLists, d => d.id),
        distributionListOptions: distributionLists.map(d => ({ label: d.name, value: d.id }))
      };
    }
    case "setSelectedMemo": {
      return { ...state, selectedMemo, _submitted: submitted ? Math.random() : state._submitted };
    }
    case "saveMergedChanges": {
      const { selectedMemo } = state;
      if (!selectedMemo) {
        return state;
      }

      return {
        ...state,
        _submitted: Math.random(),
        selectedMemo: {
          ...selectedMemo,
          changes: replaceMemoChangesWithMergedChanges(individualChanges, selectedMemo.changes)
        }
      };
    }
    case "undoMergedChanges": {
      const { selectedMemo } = state;
      if (!selectedMemo || !changeToRemove) {
        return state;
      }

      return {
        ...state,
        _submitted: Math.random(),
        selectedMemo: {
          ...selectedMemo,
          changes: removeMergedChangeFromChanges(changeToRemove, selectedMemo.changes, individualChanges)
        }
      };
    }
    case "reset":
      return initialState;
    default:
      return state;
  }
};

const MemoProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <MemoContext.Provider value={{ ...state, dispatch }}>{children}</MemoContext.Provider>;
};

export { MemoContext, MemoProvider };
