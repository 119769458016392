import { createContext, useReducer } from "react";
import { Action, ReactContext, ReactProps } from "../types/react";
import { EmailTemplate } from "../types/template";

type TemplateAction = Action & {
  templates?: EmailTemplate[];
  selectedTemplate?: EmailTemplate;
  submitted?: boolean;
};

type TemplateState = ReactContext<TemplateAction> & {
  templates: EmailTemplate[];
  selectedTemplate: EmailTemplate | null;
  lastUpdated: Date;
};

const initialState: TemplateState = {
  dispatch: () => {},
  templates: [],
  selectedTemplate: null,
  lastUpdated: new Date()
};

const TemplateContext = createContext(initialState);

const reducer = (state: TemplateState, action: TemplateAction): TemplateState => {
  // instance
  const { templates = [], selectedTemplate = null, submitted = false } = action;

  // logic
  switch (action.type) {
    case "setTemplates": {
      return { ...state, templates };
    }
    case "setSelectedTemplate": {
      return { ...state, selectedTemplate, lastUpdated: submitted ? new Date() : state.lastUpdated };
    }
    case "submit": {
      return { ...state, lastUpdated: new Date() };
    }
    case "reset":
      return initialState;
    default:
      return state;
  }
};

const TemplateProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <TemplateContext.Provider value={{ ...state, dispatch }}>{children}</TemplateContext.Provider>;
};

export { TemplateContext, TemplateProvider };
