import { createContext, useReducer } from "react";
import { Action, ReactContext, ReactProps } from "../types/react";

type LayoutAction = Action & {
  type: "toggleSidebar" | "togglePadding";
  showPadding?: boolean;
};

type LayoutState = ReactContext<LayoutAction> & {
  sidebarVisible: boolean;
  showPadding: boolean;
};

const initialState: LayoutState = {
  dispatch: () => {},
  sidebarVisible: true,
  showPadding: true
};

const LayoutContext = createContext(initialState);

const reducer = (state: LayoutState, action: LayoutAction): LayoutState => {
  const { type, showPadding } = action;
  // logic
  switch (type) {
    case "toggleSidebar": {
      return { ...state, sidebarVisible: !state.sidebarVisible };
    }
    case "togglePadding": {
      return { ...state, showPadding: showPadding ?? !state.showPadding };
    }
    default:
      return state;
  }
};

const LayoutProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <LayoutContext.Provider value={{ ...state, dispatch }}>{children}</LayoutContext.Provider>;
};

export { LayoutContext, LayoutProvider };
