import { createContext, useReducer } from "react";
import { Action, ReactContext, ReactProps } from "../types/react";

type LoadingAction = Action & {
  loading?: boolean;
};

type LoadingState = ReactContext<LoadingAction> & {
  loading: boolean;
};

const initialState: LoadingState = {
  dispatch: () => {},
  loading: false
};

const LoadingContext = createContext(initialState);

const reducer = (state: LoadingState, action: LoadingAction): LoadingState => {
  switch (action.type) {
    case "setLoading":
      return {
        ...state,
        loading: !!action.loading
      };
    default:
      return state;
  }
};

const LoadingProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <LoadingContext.Provider value={{ ...state, dispatch }}>{children}</LoadingContext.Provider>;
};

export { LoadingContext, LoadingProvider };
