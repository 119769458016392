/* eslint-disable no-useless-escape */
import DOMPurify from "dompurify";
export const trimToEmpty = (arg?: string | null) => (arg || "").trim();

export type TitleCaseOptions = {
  sep?: string | RegExp;
  join?: string;
};

/**
 * Turn a string into titleCase
 * @param arg value
 * @param options titleCase options
 * @returns a titleCased string
 */
export const titleCase = (arg: string, options: TitleCaseOptions = {}) => {
  const { sep = " ", join = " " } = options;
  return arg
    .split(sep)
    .map(a => a.charAt(0).toUpperCase() + a.substring(1).toLowerCase())
    .join(join);
};

export type CamelCaseOptions = {
  sep?: string | RegExp;
  join?: string;
};

/**
 * Turn a string into camelCase
 * @param arg value
 * @param options camelCase options
 * @returns a camelCased string
 */
export const camelCase = (arg: string, options: CamelCaseOptions = {}) => {
  const { sep = " ", join = "" } = options;
  const [first, ...rest] = arg.split(sep);
  return [first.toLowerCase(), ...rest.map(a => a.charAt(0).toUpperCase() + a.substring(1).toLowerCase())].join(join);
};

/**
 * generate a random string of size 'length'
 * @param length num of digits
 * @returns string
 */
export const randomString = (length: number) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * Test the string for the search query
 * @param field the field to search
 * @param text the search query
 * @returns whether or not the text matches the field
 */
export const testField = (field: string | null | undefined, text: string) => {
  if (field) {
    const escaped = text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(escaped, "i");
    return regex.test(field);
  }

  return false;
};

/**
 * Test the fields for the search query
 * @param fields the fields to search
 * @param text the search query
 * @returns whether or not the text matches any of the fields
 */
export const testFields = (fields: (string | null | undefined)[], text: string) => {
  return fields.some(field => testField(field, text));
};

/**
 * checks if a string is blank
 * @param arg string
 * @returns boolean
 */
export const isBlank = (arg?: string | null): arg is Exclude<typeof arg, string> => !arg?.trim();

/**
 * pluralize a string
 * @param arg the string
 * @param size the number associated with it
 * @returns the pluralized string
 */
export const pluralize = (arg: string, size: number) => (size === 1 ? arg : arg + "s");

/**
 * convert an array of strings to a comma seperated string without duplicates
 * @param arg the string
 * @returns the array as a comma seperated string without duplicates
 */
export const arrayToStringNoDups = (arg: string[]): string => [...new Set(arg)].join(", ");

/**
 * join string array in a grammatical manner
 * @param tokens string array
 * @returns an oxford-comma separated list
 */
export const joinListGrammatically = (tokens: string[]) => {
  const lastIndex = tokens.length - 1;
  const oxford = tokens.length > 2 ? "," : "";
  const join = tokens.length > 1 ? oxford + " and " : "";
  return tokens.slice(0, lastIndex).join(", ") + (tokens.length > 1 ? join : "") + tokens[lastIndex];
};

/**
 * makes a link externel by conditionally prepending HTTPS://
 * @param link link to make external
 * @returns an external link
 */
export const linkify = (link: string) => (/^https?:\/\//i.test(link) ? link : `https://${link}`);

export const urlRegex =
  /^(https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/i;

export const safeStringCompare = (a?: string | null, b?: string | null) => {
  if (a == null && b == null) {
    return 0;
  }

  if (a == null && b != null) {
    return -1;
  }

  if (a != null && b == null) {
    return 1;
  }

  return a!.localeCompare(b!);
};

export const sanitizeHtml = (arg?: string | null) => DOMPurify.sanitize(arg || "");
