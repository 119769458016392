import { ValueOpt } from "best-common-react";
import { createContext, useReducer } from "react";
import { Action, ReactContext, ReactProps } from "../types/react";
import { GroupReasonOption, StatsApiGameStatus } from "../types/statsApi";

type StatsApiAction = Action & {
  gameStatuses?: StatsApiGameStatus[];
};

type StatsApiState = ReactContext<StatsApiAction> & {
  gameStatuses: StatsApiGameStatus[];
  reasonOptions: GroupReasonOption[];
  postponeSuspendReasonOptions: ValueOpt<string>[];
};

const initialState: StatsApiState = {
  dispatch: () => {},
  gameStatuses: [],
  reasonOptions: [],
  postponeSuspendReasonOptions: []
};

const StatsApiContext = createContext(initialState);

const createReasonOptions = (statuses: StatsApiGameStatus[]) => {
  const statusGroups: { [x: string]: ValueOpt<string>[] } = {};

  // create groups
  statuses.forEach(gameStatus => {
    if (gameStatus.detailedState.includes(":")) {
      const key = gameStatus.detailedState.split(":")[0];
      if (!statusGroups[key]) {
        statusGroups[key] = [];
      }

      statusGroups[key].push({
        value: gameStatus.reason,
        label: gameStatus.reason
      });
    }
  });

  // now create the list of options
  return statusGroups;
};

const reducer = (state: StatsApiState, action: StatsApiAction): StatsApiState => {
  switch (action.type) {
    case "setGameStatuses": {
      const { gameStatuses = [] } = action;
      const statusGroups = createReasonOptions(gameStatuses);
      const unique = new Set<string>();
      const postponeSuspend = statusGroups.Suspended?.filter(v => {
        if (!unique.has(v.value)) {
          unique.add(v.value);
          return true;
        }

        return false;
      });

      return {
        ...state,
        gameStatuses,
        reasonOptions: Object.entries(statusGroups).map(([label, options]) => ({ label, options })),
        postponeSuspendReasonOptions: [...(postponeSuspend || []), { label: "Other", value: "Other" }]
      };
    }
    default:
      return state;
  }
};

const StatsApiProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <StatsApiContext.Provider value={{ ...state, dispatch }}>{children}</StatsApiContext.Provider>;
};

export { StatsApiContext, StatsApiProvider };
