import { ValueOpt } from "best-common-react";
import { createContext, useReducer } from "react";
import { Action, ReactContext, ReactProps } from "../types/react";
import { Report, ReportFilters, ReportResults } from "../types/report";
import { listToDict } from "../utils/list";

type ReportAction = Action & {
  reports?: Report[];
  reportFilters?: ReportFilters;
  reportResults?: ReportResults;
  selectedReport?: Report;
};

type ReportState = ReactContext<ReportAction> & {
  reports: Report[];
  reportFilters: ReportFilters;
  reportOptions: ValueOpt<Report>[];
  reportResults: ReportResults | null;
  selectedReport: Report | null;
};

const initialState: ReportState = {
  dispatch: () => {},
  reports: [],
  reportFilters: {},
  reportOptions: [],
  reportResults: null,
  selectedReport: null
};

const ReportContext = createContext(initialState);

const reducer = (state: ReportState, action: ReportAction): ReportState => {
  switch (action.type) {
    case "setReports": {
      const reports = [...(action.reports || [])].sort((a, b) => a.name.localeCompare(b.name));
      return {
        ...state,
        reports,
        reportOptions: reports.map(r => ({ label: r.name, value: r }))
      };
    }
    case "setReportFilters":
      return {
        ...state,
        reportFilters: action.reportFilters || {}
      };
    case "setSelectedReport":
      return {
        ...state,
        selectedReport: action.selectedReport || null,
        reportResults: null
      };
    case "setReportResults":
      return {
        ...state,
        reportResults: action.reportResults || null
      };
    case "editReport": {
      const reports = state.reports.map(r => (r.id === action.selectedReport?.id ? action.selectedReport : r));
      return {
        ...state,
        reports,
        reportOptions: reports.map(r => ({ label: r.name, value: r }))
      };
    }
    case "bulkEditReportRoles": {
      const reportMap = listToDict(action.reports || [], r => r.id);
      const reports = state.reports.map(r => reportMap[r.id] || r);
      return {
        ...state,
        reports,
        reportOptions: reports.map(r => ({ label: r.name, value: r }))
      };
    }
    case "reset":
      return {
        ...initialState,
        reports: state.reports,
        reportOptions: state.reportOptions
      };
    default:
      return state;
  }
};

const ReportProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <ReportContext.Provider value={{ ...state, dispatch }}>{children}</ReportContext.Provider>;
};

export { ReportContext, ReportProvider };
